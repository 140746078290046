<template>
  <div class="page">
    <Tables
      ref="xTable"
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :searchFormData="searchFormData"
      :showRefresh="true"
      :showSearch="true"
      @getList="getList"
      @clearSearch="clearSearch"
    >
      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-input
          v-model="searchFormData.tkno"
          size="small"
          clearable
          placeholder="按退款号查询"
        ></el-input>
      </el-form-item>
<!--      <el-form-item slot="search-item">-->
<!--        <el-input-->
<!--          v-model="searchFormData.nickname"-->
<!--          size="small"-->
<!--          clearable-->
<!--          placeholder="按申请人查询"-->
<!--        ></el-input>-->
<!--      </el-form-item>-->
      <el-form-item slot="search-item">
        <el-select
          v-model="searchFormData.refund_status"
          placeholder="按审核状态查询"
          clearable
          size="small"
        >
          <el-option
            label="待审核"
            :value="10"
          >
          </el-option>
          <el-option
            label="已审核"
            :value="20"
          >
          </el-option>
          <el-option
                  label="拒绝"
                  :value="30"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <!--表格内容-->
      <vxe-table-column
        slot="table-item"
        field="tkno"
        title="退款号"
        align="center"
        min-width="100px"
      />
      <vxe-table-column slot="table-item" title="申请人" align="center" min-width="80px" class="vxe-cell">
        <template v-slot="{ row }">
          <span>{{row.nickname}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="order_no"
        title="充值订单号"
        align="center"
        min-width="100px"
      />
      <vxe-table-column
        slot="table-item"
        field="refund_money"
        title="退款金额"
        align="center"
        min-width="80px"
      />
      <vxe-table-column slot="table-item" title="实际钱包余额" align="center" min-width="80px">
        <template v-slot="{ row }">
          <span>{{row.balance}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column slot="table-item" title="已扣赠送金" align="center" min-width="80px">
        <template v-slot="{ row }">
          <span>{{row.gift_money}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
              slot="table-item"
              field="refund_status"
              title="到账状态"
              align="center"
              min-width="80px"
      >
        <template v-slot="{ row }">
          <span>{{types.daoz_status[row.order_status]||'未知状态'}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
        slot="table-item"
        field="refund_status"
        title="审核状态"
        align="center"
        min-width="80px"
      >
        <template v-slot="{ row }">
          <span>{{types.unusual_status[row.refund_status]||'未知状态'}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column
              slot="table-item"
              field="operate_user_name"
              title="处理人"
              align="center"
              min-width="100px"
      />
      <vxe-table-column
        slot="table-item"
        field="create_time"
        title="申请时间"
        align="center"
        min-width="100px"
      />
      <vxe-table-column
              slot="table-item"
              field="mch_id"
              title="商户号"
              align="center"
              min-width="100px"
      />
      <vxe-table-column slot="table-item" title="操作" align="center" width="230">
        <template v-slot="{ row }">
          <el-button size="small" plain @click="workOne(row)" v-if="row.refund_status==10">通过</el-button>
          <el-button size="small" plain @click="refuse(row)" v-if="row.refund_status==10&&$hasAccess('user.recharge/refuse')">拒绝</el-button>
        </template>
      </vxe-table-column>
    </Tables>
    <!-- 退款 -->
    <el-dialog title="消费退款" :visible.sync="refundDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="450px">
      <el-form :model="refundDialogFormData"  size="mini" ref="refundForm" label-width="120px" class="form">
        <el-form-item label="退款号：">{{refundDialogFormOriData.tkno}}</el-form-item>
        <el-form-item label="退款金额：">{{refundDialogFormOriData.refund_money}}元</el-form-item>
        <el-form-item label="退款方式：" prop="refund_for">
          <el-radio-group v-model="refundDialogFormData.refund_for">
            <el-radio :label="30">现金</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" plain icon="el-icon-check" @click="saveRefund()" v-if="refundDialogFormOriData.refund_status==10&&$hasAccess('user.recharge/agree')">确 认 退 款
        </el-button>
        <el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Tables from '@/components/tables'
export default {
  name: 'user-refund-list',
  components: {
    Tables
  },
  data () {
    return {
      // 表格信息
      tableName: '退款列表',
      isLoading: false,
      tableData: [],
      totalPage: 0,
      searchFormData: {
        u_type: 10
      },
      types: {
        unusual_status: {
          10: '待审核',
          20: '已审核',
          30: '拒绝'
        },
        daoz_status: {
          10: '未到账',
          20: '已到账'
        },
        refund_for: {
          10: '钱包',
          20: '电卡',
          30: '现金',
          40: '赠送金'
        },
        refund_type: {
          10: '消费订单',
          20: '充值'
        },
        refund_model: {
          10: '自动',
          20: '系统操作'
        }
      },
      refundDialogShow: false,
      refundDialogFormOriData: {},
      refundDialogFormData:{}
    }
  },
  methods: {
    // 表格列表
    async getList (currentPage, pageSize) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      this.isLoading = true
      const searchData = this.searchFormData
      const group_id = this.$store.state.user.userInfo.group_id
      if (!searchData.group_id && group_id) {
        searchData.group_id = group_id
      }
      const res = await this.$api.Fans.GetRefund(params, searchData)
      this.tableData = res.data
      this.totalPage = res.total
      this.isLoading = false
    },
    async workOne(row) {
      this.refundDialogFormData = {
        id: row.id,
        tkno:row.tkno,
        fans_id:row.fans_id,
        order_id:row.order_id,
        refund_money: row.refund_money,
        refund_for: 30
      }
      this.refundDialogFormOriData = {
        refund_money: Number(row.refund_money),
        id: row.id,
        'refund_status':row.refund_status,
        tkno: row.tkno
      }
      this.refundDialogShow = true
    },
    // 关闭弹窗
    closeDialog() {
      this.detailDialogShow = false
      this.refundDialogShow = false
    },
    // 退款保存
    saveRefund() {
      this.$refs.refundForm.validate(async (valid) => {
        if (valid) {
          this.closeDialog()
          this.isLoading=true;
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.refundDialogFormData)
          }
          await this.$api.Fans.Refund(params);
          this.$notify({
            title: '成功',
            message: '退款成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    },
    refuse (row) {
      this.$confirm('确定要拒绝吗', '提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const params = {
          token: this.$store.state.user.token,
          form: row.id
        }
        console.log(params);
        await this.$api.Fans.Refundrefuse(params);
        this.$notify({
          title: '成功',
          message: '拒绝成功',
          type: 'success'
        })
        this.$refs.xTable.refreshTable()
      })
    },
    // 重置搜索
    clearSearch () {
      this.searchFormData = {
        u_type: 10
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
